import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import './assets/css/newcss/css/styles.css';
import './assets/css/newcss/css/newcustomcss.css'
import './assets/css/newcss/css/layout.css';
import './assets/css/admin_style.css';
import './assets/css/home_page.css';

import Dashboard from "./Pages/Dashboard";
import HomePageLayout from "Components/Common/Layout/Home";
import Latest from "Pages/Latest";
import Pakistan from "Pages/Pakistan";
import International from "Pages/International";
import Sports from "Pages/Sports";
import Entertainment from "Pages/Entertainment";
import Business from "Pages/Business";
import Science from "Pages/Science";
import Crime from "Pages/Crime";
import SingleNews from "Pages/SingleNews";
import * as timeago from 'timeago.js';
import { ur } from 'utils/urLocale';
import Articles from "Pages/Articles";
import SingleArticle from "Pages/SingleArticle";

timeago.register('vi', ur);

console.log('test')

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route element={<HomePageLayout />}>
            <Route path="latest" element={<Latest />} />
            <Route path="pakistan" element={<Pakistan />} />
            <Route path="international" element={<International />} />
            <Route path="sports" element={<Sports />} />
            <Route path="entertainment" element={<Entertainment />} />
            <Route path="business" element={<Business />} />
            <Route path="science" element={<Science />} />
            <Route path="crime" element={<Crime />} />
            <Route path="articles" element={<Articles />} />
            <Route path="news">
              <Route path=":id" element={<SingleNews />} />
            </Route>
            <Route path="articles">
              <Route path=":id" element={<SingleArticle />} />
            </Route>
          </Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
