import { Fragment } from "react"
import { NavLink } from "react-router-dom";
import LeftArrowButton from "../Buttons/LeftArrowButton";
import RightArrowButton from "../Buttons/RightArrowButton";

interface Props {
    onGoTo?: Function | null,
    sectionsName: string,
}

const SectionHeader = ({
    onGoTo,
    sectionsName,
}: Props) => {

    return (
        <Fragment>
            <div className="s-heading triangled" style={{ position: 'relative' }}>
                <h1><NavLink to="/sports" >{sectionsName}</NavLink></h1>

                {onGoTo
                    ? <span style={{
                        position: 'absolute',
                        top: '9px',
                        left: 0,
                        display: 'flex'
                    }}>
                        <LeftArrowButton onClick={() => onGoTo('prev')} />
                        <RightArrowButton onClick={() => onGoTo('next')} />
                    </span>
                    : null
                }
            </div>
        </Fragment>
    )
}

export default SectionHeader;