
interface Props{
    children: React.ReactNode
}

const Container = ({children}:Props) => {
  return (
    <div className="content-wrapper">
        <div className="container-fluid famous-tags-section">
            <div className="row">
                {children}
            </div>
        </div>
    </div>
  )
};

export default Container;
