import { configureStore } from '@reduxjs/toolkit';
import newsReducer from './reducers/news';
import categoryReducer from './reducers/category';

export function makeStore() {
    return configureStore({
        reducer: {
            newsReducer,
            categoryReducer
        },
        devTools: true
    })
}
  
const store = makeStore()

export default store

export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
