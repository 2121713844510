import LatestNews from "./LatestNews";
import Columns from "./Columns";
import FamousTags from "./FamousTags";
import NewsIndex from "./NewsIndex";
import Socials from "./Socials";
import { Fragment } from "react";

const Sidebar = () => {

    return (
        <Fragment>
            <LatestNews />
            <NewsIndex />
            <Socials />
            <Columns />
            <FamousTags />
        </Fragment>
    );
}

export default Sidebar;