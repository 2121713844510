import HomePageLayout from "Components/Common/Layout/Home";
import BusinessNews from "Components/Home/BusinessNews";
import Headlines from "Components/Home/Headlines";
import InternationalNews from "Components/Home/InternationalNews";
import LatestArticles from "Components/Home/LatestArticles";
import SliderNews from "Components/Home/SliderNews";
import Sports from "Components/Home/Sports";
import SuggestedNews from "Components/Home/SuggestedNews";
import Sidebar from "Components/Sidebar";

const Dashboard = () => {

    return (
        <HomePageLayout>
            <div className="headline-famous-section container-fluid">
                <div className="col-md-9 col-sm-12">
                    <SliderNews />
                    <Headlines />
                    <InternationalNews />
                    <Sports />
                    <BusinessNews />
                    <LatestArticles />
                    <SuggestedNews />
                </div>
                <div className="col-md-3 col-sm-12">
                    <Sidebar />
                </div>
            </div>
        </HomePageLayout>
    );
}

export default Dashboard;