import { useAppDispatch, useAppSelector } from "app/hook";
import { getLatestArticlesAction, selectLatestArticles } from "app/reducers/news";
import ArticlesBox from "Components/ArticlesBox";
import CategoryLayout from "Components/Common/Layout/Category";
import NewsContainer from "Components/Common/Layout/NewsContainer";
import Sidebar from "Components/Common/Layout/Sidebar";
import ProminentNews from "Components/News/ProminentNews";
import Pagination from "Components/Pagination";
import FamousTags from "Components/Sidebar/FamousTags";
import { NEWS_PER_PAGE } from "Constants";
import { useEffect, useState } from "react";

const Articles = () => {

    const[page, setPage] = useState(0);

    const dispatch = useAppDispatch();

    const allArticles = useAppSelector(selectLatestArticles);
    const {rows = [], count = 0} = allArticles || {};

    useEffect(() => {
        dispatch(getLatestArticlesAction(NEWS_PER_PAGE, 0));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onChangePage = (page: number) => {
        dispatch(getLatestArticlesAction(NEWS_PER_PAGE, page));
        setPage(page);
    }

    const noOfPages = Math.ceil(count/NEWS_PER_PAGE);

    const prominentArticles = rows.slice(0,5);

    return (
        <CategoryLayout>
            <Sidebar>
                
                <ProminentNews heading='نمایاں آرٹیکلز' prominentNews={prominentArticles} redirectUrl="/articles/"/>
                
                <FamousTags />
                
            </Sidebar>

            <NewsContainer>
                
                <ArticlesBox heading='آرٹیکلز' newsItems={rows} />

                <Pagination onChangePage={onChangePage} noOfPages={noOfPages} page={page}/>

            </NewsContainer>
        </CategoryLayout>
    );
}

export default Articles;