interface Props{
    children: React.ReactNode
}

const Sidebar = ({children}: Props) => {
    return (
        <div className="col-md-3 col-sm-12">
            {children}
        </div>
    )
};

export default Sidebar;
