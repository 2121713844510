import { useAppDispatch, useAppSelector } from "app/hook";
import { getAllNewsAction, selectInternationalNews } from "app/reducers/news";
import { useEffect, useState } from "react";
import 'tiny-slider/dist/tiny-slider.css';
import { NavLink } from "react-router-dom";
import NoImageAvailable from 'assets/images/default_images/no-img.gif';
import { tns } from 'tiny-slider/src/tiny-slider.js';
import SectionHeader from "Components/Common/SectionHeader";
import MediaDetail from "Components/Common/MediaDetail";

const InternationalNews = () => {

    const dispatch = useAppDispatch();
    const [slider, setSlider] = useState<any>(null!);

    const newsData = useAppSelector(selectInternationalNews);
    const { data = [], isLoading = true } = newsData || {};

    useEffect(() => {
        dispatch(getAllNewsAction('international', 8, 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (!isLoading && data.length) {
            const tinySlider = tns({
                container: "#base4",
                items: 2,
                slideBy: 2,
                autoplay: false,
                nav: false,
                loop: true,
                axis: "horizontal",
                controls: false
            });

            setSlider(tinySlider);
        }
    }, [data, isLoading])

    const onGoTo = (dir: string) => {
        slider.goTo(dir);
    }


    return (
        <div className="international-news">
            <SectionHeader sectionsName="عالمی خبریں" onGoTo={onGoTo} />
            <div className="slider-container">
                <div id="base_wrapper">
                    <div className="base4" id="base4">
                        {data.map((newsArray: any[], index: number) => {
                            return (
                                <div key={index} className={'slide ' + (newsArray.length > 1 ? 'tripple' : 'single')}>
                                    {newsArray.map((news: any, index: number) => {
                                        const { heading, pub_date, image_link, pk_id } = news;
                                        const img = image_link || NoImageAvailable;
                                        return (
                                            <div key={index} className="media-img">
                                                <div className={`img img-${index + 1}`}>

                                                    <NavLink to={`/news/${pk_id}`}>
                                                        <img
                                                            className="b-lazy"
                                                            src={img}
                                                            alt="<?= mb_substr($heading, 0 , 25)?>"
                                                        />
                                                        <div className="media-caption">
                                                            <MediaDetail
                                                                heading={heading}
                                                                dateTime={pub_date}
                                                                headlineStyle="caption-text"
                                                                timeStyle="media-time yellow-color"
                                                            />
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div >
                            )
                        })}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default InternationalNews;