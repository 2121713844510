import { Pagination } from "types/common";
import { NewsItem, NewsItemResponse, NewsItems } from "types/news";
import apiConstants from "./constants/apiConstants";
import http from "./core/HttpService";


export const getSuggestedNews = async (limit: number) => {

    try {
        const result = await http.get(`/famousNews?limit=${limit}`);
        return Promise.resolve(result.data);
    }
    catch (error) {
        return Promise.resolve([]);
    }
}

export const getAllNews = async (category:string, pagination: Pagination): Promise<NewsItemResponse> => {
    const {limit, offset} = pagination;
    try {
        const result = await http.get(`/${category}?limit=${limit}&offset=${offset}`);
        return Promise.resolve(result.data);
    }
    catch (error) {
        return Promise.resolve({rows: [], count: 0});
    }
}

export const getNews = async (id: number): Promise<NewsItem> => {
    try {
        const result = await http.get(`${apiConstants.NEWS}/${id}`);
        return Promise.resolve(result.data);
    }
    catch (error) {
        return Promise.resolve(null!);
    }
}

export const getColumn = async (id: number): Promise<any> => {
    try {
        const result = await http.get(`${apiConstants.COLUMN}/${id}`);
        return Promise.resolve(result.data);
    }
    catch (error) {
        return Promise.resolve(null!);
    }
}

export const getRecommendedNews = async () => {
    try {
        const result = await http.get(`${apiConstants.NEWS}/recommended`);
        return Promise.resolve(result.data);
    }
    catch (error) {
        return Promise.resolve([]);
    }
}


export const getFeaturedNews = async (type: string, pagination: Pagination): Promise<NewsItems> => {
    const {limit, offset} = pagination;
    try {
        const result = await http.get(`/featuredNews/${type}?limit=${limit}&offset=${offset}`);
        return Promise.resolve(result.data);
    }
    catch (error) {
        return Promise.resolve([]);
    }
}

export const getFamousNews = async (limit: number): Promise<NewsItems> => {

    try {
        const result = await http.get(`/famousNews?limit=${limit}`);
        return Promise.resolve(result.data);
    }
    catch (error) {
        return Promise.resolve([]);
    }
}

export const getLatesNews = async (pagination: Pagination) => {
    const{limit, offset} = pagination
    try {
        const result = await http.get(`/latestNews?limit=${limit}&offset=${offset}`);
        return Promise.resolve(result.data);
    }
    catch (error) {
        return Promise.resolve([]);
    }
}

export const getProminentColumns = async (pagination: Pagination) => {

    const{limit, offset} = pagination;
    try {
        const result = await http.get(`/prominentColumns?limit=${limit}&offset=${offset}`);
        return Promise.resolve(result.data);
    }
    catch (error) {
        return Promise.resolve([]);
    }

}

export const getLatestArticles = async (pagination: Pagination) => {
    const {limit, offset} = pagination;

    try {
        const result = await http.get(`/latestArticles?limit=${limit}&offset=${offset}`);
        return Promise.resolve(result.data);
    }
    catch (error) {
        return Promise.resolve([]);
    }
}

export const getColumns = async () => {

    try {
        const result = await http.get(`/columns`);
        return Promise.resolve(result.data);
    }
    catch (error) {
        return Promise.resolve([]);
    }

}

export const getColumnists = async () => {
    
    try {
        const result = await http.get(`/columnists`);
        return Promise.resolve(result.data);
    }
    catch (error) {
        return Promise.resolve([]);
    }
}
