import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppState } from "app/store";
import { getAllNews, getFamousNews, getFeaturedNews, getColumns, getLatesNews, getLatestArticles, getNews, getProminentColumns, getSuggestedNews, getColumnists, getRecommendedNews } from 'services/newsService';
import { Pagination } from 'types/common';
import { NewsItem, NewsItemResponse, NewsItems, FeaturedNews, FeaturedNewsItem } from 'types/news';
import { sliderNewsFormatting } from 'utils/sliderNewsFormatting';
import { getColumn } from '../../services/newsService';

interface NewsSlice {
    internationalNews: { data: any, isLoading: boolean };
    isLoading: boolean;
    news: NewsItem;
    leftFeaturedNews: NewsItems;
    rightFeaturedNews: NewsItems;
    sliderNews: NewsItems;
    sportsNews: FeaturedNewsItem;
    businessNews: FeaturedNewsItem;
    famousNews: NewsItems;
    latestNews: NewsItemResponse;
    prominentColumns: any[];
    suggestedNews: FeaturedNewsItem;
    latestArticles: { rows: any[], count: number };
    tickerNews: NewsItems;
    prominentNews: NewsItems;
    columns: any[];
    columnists: any[];
    recommendedNews: NewsItems;
    featuredNews: FeaturedNews | any;
    loader: any
}

const initialState: NewsSlice = {
    internationalNews: {
        data: [],
        isLoading: true
    },
    news: null!,
    isLoading: false,
    leftFeaturedNews: [],
    rightFeaturedNews: [],
    sliderNews: [],
    sportsNews: {
        data: [],
        isLoading: false
    },
    businessNews: {
        data: [],
        isLoading: false
    },
    famousNews: [],
    latestNews: null!,
    prominentColumns: [],
    suggestedNews: {
        data: [],
        isLoading: false
    },
    latestArticles: null!,
    tickerNews: [],
    prominentNews: [],
    columns: [],
    columnists: [],
    recommendedNews: [],
    featuredNews: {
        latestNews: {
            data: [],
            isLoading: false
        },
        tickerNews: {
            data: [],
            isLoading: false
        },
        prominentNews: {
            data: [],
            isLoading: false
        },
        headlinesNews: {
            data: [],
            isLoading: false
        }
    },
    loader: null!
}

export const newsSlice = createSlice({
    name: 'newsReducer',
    initialState,
    reducers: {
        // setInternationalNews: (state, action:PayloadAction<NewsItemResponse>) => {
        //     state.internationalNews = action.payload;
        // },
        // setSportsNews: (state, action:PayloadAction<NewsItemResponse>) => {
        //     state.sportsNews = action.payload;
        // },
        // setBusinessNews: (state, action:PayloadAction<NewsItemResponse>) => {
        //     state.businessNews = action.payload;
        // },
        setSingleNews: (state, action: PayloadAction<NewsItem>) => {
            state.news = action.payload;
        },
        setFeaturedNews: (state, action: PayloadAction<{ featuredNews: FeaturedNewsItem, type: string }>) => {

            const { type, featuredNews } = action.payload;

            state.featuredNews = {
                ...state.featuredNews,
                [type]: featuredNews
            };
        },
        setFamousNews: (state, action: PayloadAction<NewsItems>) => {
            state.famousNews = action.payload;
        },
        setLatestNews: (state, action: PayloadAction<NewsItemResponse>) => {
            state.latestNews = action.payload;
        },
        setProminentColumns: (state, action: PayloadAction<any[]>) => {
            state.prominentColumns = action.payload;
        },
        setSuggestedNews: (state, action: PayloadAction<FeaturedNewsItem>) => {
            state.suggestedNews = action.payload;
        },
        setLatestArticles: (state, action: PayloadAction<{ rows: any[], count: number }>) => {
            state.latestArticles = action.payload;
        },
        setTickerNews: (state, action: PayloadAction<NewsItems>) => {
            state.tickerNews = action.payload;
        },
        setProminentNews: (state, action: PayloadAction<NewsItems>) => {
            state.prominentNews = action.payload;
        },
        setLoading: (state) => {
            state.isLoading = true;
        },
        clearLoading: (state) => {
            state.isLoading = false;
        },
        setColumns: (state, action: PayloadAction<any[]>) => {
            state.columns = action.payload;
        },
        setColumnists: (state, action: PayloadAction<any[]>) => {
            state.columnists = action.payload;
        },
        setRecommendedNews: (state, action: PayloadAction<NewsItems>) => {
            state.recommendedNews = action.payload;
        },
        setLoader: (state, action: PayloadAction<{ isLoading: boolean, type: string }>) => {
            const { type, isLoading } = action.payload;
            state.loader = {
                ...state.loader,
                [type]: isLoading
            };
        },
        setFeaturedNewsLoading: (state, action: PayloadAction<{ isLoading: boolean, type: any}>) => {
            const { isLoading, type } = action.payload;

            state.featuredNews = {
                ...state.featuredNews,
                [type]: {
                    ...state.featuredNews[type],
                    isLoading
                }
            }
        },
        setHomeNews: (state, action: PayloadAction<{ featuredNews: { data: NewsItems, isLoading: boolean }, category: 'internationalNews' | 'sportsNews' | 'businessNews' }>) => {
            const { featuredNews, category } = action.payload;

            state[category] = featuredNews
        },
        setHomeNewsLoading: (state, action: PayloadAction<{ isLoading: boolean, category: 'internationalNews' | 'sportsNews' | 'businessNews' }>) => {
            const { isLoading, category } = action.payload;
            state[category].isLoading = isLoading;
        },
        setSuggestedNewsLoading: (state, action: PayloadAction<boolean>) => {
            state.suggestedNews.isLoading = action.payload
        },
    }
})

export default newsSlice.reducer

export const {
    setFamousNews,
    setLatestNews,
    // setBusinessNews, 
    // setInternationalNews, 
    // setSportsNews, 
    setLoading,
    clearLoading,
    setSingleNews,
    setFeaturedNews,
    setProminentColumns,
    setSuggestedNews,
    setLatestArticles,
    setTickerNews,
    setProminentNews,
    setColumns,
    setColumnists,
    setRecommendedNews,
    setLoader,
    setFeaturedNewsLoading,
    setHomeNews,
    setHomeNewsLoading,
    setSuggestedNewsLoading
} = newsSlice.actions

export const selectInternationalNews = (state: AppState): { data: any, isLoading: boolean } => state.newsReducer.internationalNews;

export const selectSportsNews = (state: AppState): FeaturedNewsItem => state.newsReducer.sportsNews;

export const selectBusinessNews = (state: AppState): FeaturedNewsItem => state.newsReducer.businessNews;

export const selectFeaturedNews = (state: AppState): FeaturedNews => state.newsReducer.featuredNews;

export const selectLatestNews = (state: AppState): NewsItemResponse => state.newsReducer.latestNews;

export const selectFamousNews = (state: AppState): NewsItems => state.newsReducer.famousNews;

export const selectNews = (state: AppState): NewsItem => state.newsReducer.news;

export const selectProminentColumns = (state: AppState): any[] => state.newsReducer.prominentColumns;

export const selectSuggestedNews = (state: AppState): FeaturedNewsItem => state.newsReducer.suggestedNews;

export const selectLatestArticles = (state: AppState): { rows: any[], count: number } => state.newsReducer.latestArticles;

export const selectTickerNews = (state: AppState): NewsItems => state.newsReducer.tickerNews;

export const selectProminentNews = (state: AppState): NewsItems => state.newsReducer.prominentNews;

export const selectColumns = (state: AppState): NewsItems => state.newsReducer.columns

export const selectColumnists = (state: AppState): NewsItems => state.newsReducer.columnists;

export const selectRecommendedNews = (state: AppState): NewsItems => state.newsReducer.recommendedNews;

export const selectLoader = (state: AppState): any => state.newsReducer.loader;

export const getFeaturedNewsAction = (type: string, limit: number = 5, offset: number = 0) => async (dispatch: AppDispatch) => {

    dispatch(setFeaturedNewsLoading({ isLoading: true, type: `${type}News`}))

    const featuredNews = await getFeaturedNews(type, { limit, offset });

    dispatch(setFeaturedNews({ featuredNews: { data: featuredNews, isLoading: false }, type: `${type}News` }))

    dispatch(setFeaturedNewsLoading({ isLoading: false, type: `${type}News`}))
}

export const getAllNewsAction = (category: string, limit = 10, offset = 0) => async (dispatch: AppDispatch) => {

    dispatch(setHomeNewsLoading({ isLoading: true, category: `${category}News` as 'internationalNews' | 'sportsNews' | 'businessNews' }));

    const allNews = await getAllNews(category, { limit, offset });

    const data = category === 'international' ? sliderNewsFormatting(allNews.rows) : allNews.rows;


    dispatch(setHomeNews({ featuredNews: { data, isLoading: false }, category: `${category}News` as 'internationalNews' | 'sportsNews' | 'businessNews' }))

    // if(category === 'international'){
    //     dispatch(setInternationalNews(allNews));
    // }

    // if(category === 'sports'){
    //     dispatch(setSportsNews(allNews));
    // }

    // if(category === 'business'){
    //     dispatch(setBusinessNews(allNews));
    // }
}

export const getNewsAction = (id: number) => async (dispatch: AppDispatch) => {

    const news = await getNews(id);

    dispatch(setSingleNews(news));

}

export const getColumnAction = (id: number) => async (dispatch: AppDispatch) => {

    const news = await getColumn(id);

    dispatch(setSingleNews(news));

}

export const getRecommendedNewsAction = () => async (dispatch: AppDispatch) => {

    const recommendedNews = await getRecommendedNews();

    dispatch(setRecommendedNews(recommendedNews))
}

export const getFamousNewsAction = (limit: number) => async (dispatch: AppDispatch) => {

    const famousNews = await getFamousNews(limit);

    dispatch(setFamousNews(famousNews));
}

export const getLatesNewsAction = (pagination: Pagination) => async (dispatch: AppDispatch) => {

    const latestNews = await getLatesNews(pagination);

    dispatch(setLatestNews(latestNews));
}

export const getProminentColumnsAction = (pagination: Pagination) => async (dispatch: AppDispatch) => {

    const prominentColumns = await getProminentColumns(pagination);

    dispatch(setProminentColumns(prominentColumns));
}

export const getSuggestedNewsAction = (limit: number) => async (dispatch: AppDispatch) => {

    dispatch(setSuggestedNewsLoading(true))

    const suggestedNews = await getSuggestedNews(limit);

    dispatch(setSuggestedNews({ data: suggestedNews, isLoading: false }));
}

export const getLatestArticlesAction = (limit: number, offset: number) => async (dispatch: AppDispatch) => {

    const latestArticles = await getLatestArticles({ limit, offset });

    dispatch(setLatestArticles(latestArticles));
}

export const getColumnsAction = () => async (dispatch: AppDispatch) => {

    const latestArticles = await getColumns();

    dispatch(setColumns(latestArticles));
}

export const getColumnistsAction = () => async (dispatch: AppDispatch) => {

    const columnists = await getColumnists();

    dispatch(setColumnists(columnists));
}
