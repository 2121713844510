import { useAppDispatch, useAppSelector } from "app/hook";
import { getFeaturedNewsAction, selectFeaturedNews } from "app/reducers/news";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { tns } from "tiny-slider/src/tiny-slider";

const LatestNews = () => {

    const dispatch = useAppDispatch();
    const { NationalNews } = useAppSelector(selectFeaturedNews)
    const { data = [], isLoading = false } = NationalNews || {};

    useEffect(() => {
        dispatch(getFeaturedNewsAction('National', 10, 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isLoading && data.length) {
            tns({
                container: '.base6',
                slideBy : 1,
                items: 1,
                speed: 10,
                autoplay: true,
                loop: true,
                autoplayDirection: "forward"
            });
        }
    }, [data, isLoading])

    return (
        <div className="latest-news container-fluid" style={{ display: 'block' }}>
            <div className="row">
                <div className="col-sm-3 heading col-sm-push-9 p0">
                    <p className="latest-p">تازہ ترین خبریں</p>
                </div>
                <div className="col-sm-9 col-sm-pull-3 p0">
                    <div className="slider-container">
                        <div id="base_wrapper">
                            <div className="base6" id="base6">
                                {data.map(({ heading, pk_id }) =>
                                    <div key={pk_id} className="item" style={{ position: 'relative' }}>
                                        <p>
                                            <NavLink to={`/news/${pk_id}`}>
                                                {heading}
                                            </NavLink>
                                        </p><span> </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LatestNews;
