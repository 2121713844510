import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hook';
import { NavLink } from 'react-router-dom';
import { getSuggestedNewsAction, selectSuggestedNews } from 'app/reducers/news';
import NoImageAvailable from 'assets/images/default_images/no-img.gif';
import { tns } from 'tiny-slider/src/tiny-slider';
import SectionHeader from 'Components/Common/SectionHeader';
import MediaDetail from 'Components/Common/MediaDetail';

const SuggestedNews = () => {

    const dispatch = useAppDispatch();

    const [slider, setSlider] = useState<any>(null!);

    const suggestedNews = useAppSelector(selectSuggestedNews);
    const { isLoading = false, data = [] } = suggestedNews || {};

    useEffect(() => {
        dispatch(getSuggestedNewsAction(7));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (!isLoading && data && data?.length) {
            const tinySlider = tns({
                container: ".base1",
                items: 4,
                slideBy: 4,
                autoplay: false,
                nav: false,
                loop: true,
                axis: "horizontal",
                controls: false
            });

            setSlider(tinySlider);
        }
    }, [data, isLoading])

    const onGoTo = (dir: string) => {
        slider.goTo(dir);
    }

    return (
        <div className="business-news">
            <SectionHeader sectionsName='آپ کیلئے تجویز کردہ‎‎' onGoTo={onGoTo} />
            <div className="slider-container">
                <div id="base_wrapper">
                    <div className="base1" id="base1">
                        {data?.map((news, index) => {
                            const { heading, image_link, pub_date, pk_id } = news;
                            const img = image_link || NoImageAvailable;

                            return (
                                <div key={pk_id} style={{ position: "relative" }} className="item">
                                    <div className={`img img-${index}`}>
                                        <NavLink to={`/news/${pk_id}`}>
                                            <span>
                                                <img
                                                    className="b-lazy b-loaded"
                                                    src={img}
                                                    alt=""
                                                />
                                                <MediaDetail heading={heading} dateTime={pub_date} timeStyle='media-time yellow-color' />
                                            </span>
                                        </NavLink>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SuggestedNews;
