import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hook';
import { getFamousNewsAction, getLatesNewsAction, selectFamousNews, selectLatestNews } from 'app/reducers/news';
import { NewsItem } from 'types/news';
import { NavLink } from 'react-router-dom';
import NoImageAvailable from 'assets/images/default_images/no-img.gif';
import MediaDetail from 'Components/Common/MediaDetail';

const LIMIT = 6;

const LatestNews = () => {
    const [isLatestNewsActive, setIsLatestNewsActive] = useState(true);

    const dispatch = useAppDispatch();

    const famousNews = useAppSelector(selectFamousNews);
    const latestNews = useAppSelector(selectLatestNews);

    useEffect(() => {
        dispatch(getFamousNewsAction(LIMIT));
        dispatch(getLatesNewsAction({ limit: 5, offset: 7 }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const newsItems: any = isLatestNewsActive ? latestNews?.rows : famousNews;

    return (
        <div className='recents-famous'>
            <ul className="mynav">
                <li className={!isLatestNewsActive ? 'active' : ''}>
                    <div onClick={() => setIsLatestNewsActive(false)}>
                        <span>مشہور‎</span>
                    </div>
                </li>
                <li className={isLatestNewsActive ? 'active' : ''}>
                    <div onClick={() => setIsLatestNewsActive(true)}>
                        <span>تازہ ترین</span>
                    </div>
                </li>
            </ul>
            <div className="tab-content">
                <ul>
                    {newsItems?.map((news: NewsItem) => {
                        const { pk_id, image_link, pub_date, heading } = news;
                        const img = image_link || NoImageAvailable;
                        return (
                            <li key={pk_id} style={{ marginLeft: 'unset' }}>
                                <NavLink to={`/news/${pk_id}`}>
                                    <div className="media-thumb">
                                        <img
                                            className="b-lazy b-loaded"
                                            src={img}
                                            alt=""
                                        />
                                    </div>
                                    <MediaDetail heading={heading} dateTime={pub_date} />
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
}

export default LatestNews;