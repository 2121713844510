import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";


const HomePageLayout = (props:any) => {

    return (
        <>
            <Header />
            {props.children}
            <Outlet />
            <Footer />
        </>
    );
}

export default HomePageLayout;