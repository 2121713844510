import SectionHeader from 'Components/Common/SectionHeader';
import YellowClock from 'assets/images/icons/clock-yellow.webp';
import { NavLink } from 'react-router-dom';
import TimeAgo from 'timeago-react';
import { NewsItems } from 'types/news';
import { COLUMNISTS } from 'utils/columnistsImages';

interface Props {
    prominentNews: NewsItems;
    heading?: string;
    redirectUrl?: string
}

const ProminentNews = ({prominentNews, heading, redirectUrl}: Props) => {

    return (
        <div className="prominent-news">
            <SectionHeader sectionsName={heading || 'نمایاں خبریں'}/>
            <div className="slider-container">
                <div id="base_wrapper">
                    <div className="base5" id="base5">

                        {prominentNews.map((news, index) => {
                            const {heading, pub_date, image_link, pk_id} = news;
                            const formattedDateTime = pub_date.slice(0, 19).replace('T', ' ');
                            return (
                                <div className="item">
                                    <div className={`img img-${index+1}`}>
                                        <NavLink to={redirectUrl ? redirectUrl + pk_id : '/'}>
                                            <div className="news-img">
                                                <img 
                                                    className="b-lazy b-loaded" 
                                                    src={ COLUMNISTS[image_link] ? COLUMNISTS[image_link] : image_link}
                                                    alt = "" 
                                                />
                                            </div>
                                            <div className="news">
                                                <p className="news-text">
                                                    {heading}
                                                </p>
                                                <p className="news-time">
                                                    <img 
                                                        alt=""
                                                        className = "clock-icon" 
                                                        src={YellowClock}
                                                    /> 
                                                    <span className="timeago" title={formattedDateTime} >
                                                        <TimeAgo 
                                                            datetime={formattedDateTime}
                                                            locale='vi'
                                                        />
                                                    </span>
                                                </p>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProminentNews;
