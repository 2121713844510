import React from 'react';
import LoaderImage from 'assets/images/default_images/loading.gif';

const Loader = () => {
  return (
    <img 
        className="b-lazy" 
        src={LoaderImage}
        alt = "Loader"
    />
  )
};

export default Loader;
