import YoutubeIcon from 'assets/css/newcss/resources/images/icon/yt.png';
import GPlusIcon from 'assets/css/newcss/resources/images/icon/gplus.png';
import TwitterIcon from 'assets/css/newcss/resources/images/icon/tw.png';
import FacebookIcon from 'assets/css/newcss/resources/images/icon/fb.png';
import SectionHeader from 'Components/Common/SectionHeader';

const socialLinks = [
    {
        link: "https://twitter.com/NewsLink16",
        className: "tw",
        count: 3031,
        name: "ٹوئیٹر",
        icon: TwitterIcon,
        alt: "twitter"
    },
    {
        link: "https://www.facebook.com/LinkNewsPK",
        className: "fb",
        count: 9738,
        name: "فیس بک",
        icon: FacebookIcon,
        alt: "facebook"
    },
    {
        link: "https://plus.google.com/u/0/109034385609964671449",
        className: "gp",
        count: 2189,
        name: "گوگل پلس",
        icon: GPlusIcon,
        alt: "gplus"
    },
    {
        link: "https://www.youtube.com/channel/UCT6DWi1nEqjdmo1K1sjOzCA",
        className: "yt",
        count: 5480,
        name: "یوٹیوب",
        icon: YoutubeIcon,
        alt: "youtube"
    }
];

const Socials = () => {
  return (
    <div className="keep-in-touch">
        <SectionHeader sectionsName='جڑے رہیے' />
        <div className="social-list">
            <ul>
                {socialLinks.map((social, index) => {
                    const {link, name, count, icon, alt, className} = social;
                    return (
                        <li key={index}>
                            <a target="_blank" rel="noreferrer" href={link}>
                                <div className={`social-box ${className}`}>
                                    <div className="right data tw">
                                        <p className="count">{count}</p>
                                        <p className="name">{name}</p>
                                    </div>
                                    <div className="right">
                                        <img src={icon} alt={alt} />
                                    </div>
                                </div>
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>
    </div>
  )
};

export default Socials;
