import SectionHeader from "Components/Common/SectionHeader";

const FamousTags = () => {
  return (
    <div className="famous-tags">
      <SectionHeader sectionsName="مقبول ٹیگ‎‎‎" />
      <div className="tags-list" style={{ wordSpacing: '3px' }}>
        <p>

        </p>
      </div>
    </div>
  )
};

export default FamousTags;
