import { NewsItems } from "types/news";
import NoImageAvailable from 'assets/images/default_images/no-img.gif';
import { NavLink } from "react-router-dom";
import SectionHeader from "Components/Common/SectionHeader";
import MediaDetail from "Components/Common/MediaDetail";
import { COLUMNISTS } from "utils/columnistsImages";

interface Props {
    heading: string;
    newsItems: NewsItems;
}

const ArticlesBox = ({ heading, newsItems }: Props) => {

    return (
        <div className="related-news">
            <SectionHeader sectionsName={heading} />
            <div className="news-list">
                <ul>

                    {newsItems.map(newsItem => {

                        const { heading, pub_date, description = '', image_link, pk_id } = newsItem || {};
                        const img = COLUMNISTS[image_link] || NoImageAvailable;

                        return (
                            <li className="col-md-4 col-sm-12 news-data">
                                <div className="news-box" style={{ height: '440px' }}>
                                    <div className="news-media-img news-media">
                                        <div style={{ lineHeight: '250px', height: '100%' }}>
                                            <NavLink to={`/articles/${pk_id}`}>
                                                <img
                                                    className="b-lazy b-loaded"
                                                    src={img}
                                                    alt=""
                                                />
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="media-data">
                                        <MediaDetail
                                            heading={heading}
                                            dateTime={pub_date}
                                            headlineStyle="headline"
                                            timeStyle="headline-time"
                                        />
                                        <p className="headline-desc" style={{ maxHeight: '38px' }}>
                                            {description.slice(0, 100)}
                                            &nbsp;&nbsp;&nbsp;
                                            <NavLink
                                                to={`/articles/${pk_id}`}
                                                title="مزید پڑہیں"
                                                style={{
                                                    fontSize: '14px',
                                                    color: '#2196F4',
                                                    fontWeight: 'bold'
                                                }}
                                            >مزید پڑہیں
                                            </NavLink>
                                        </p>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
};

export default ArticlesBox;
