interface Props{
    children: React.ReactNode
}

const NewsContainer = ({children}: Props) => {
  return (
    <div className="col-md-9 col-sm-12 right-content border-left">
      {children}
    </div>
  )
};

export default NewsContainer;
