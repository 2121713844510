export function ur(number: number, index: number) {
    return [
        ['کچھ لمحے پہلے', 'کچھ لمحے میں'],
        ['%s سیکنڈز پہلے', 'in %ss'],
        [' ایک منٹ پہلے', 'in 1m'],
        ['%sمنٹ پہلے', 'in %sm'],
        ['ایک گھنٹہ پہلے', '1h میں'],
        ['%sگھنٹے پہلے', 'in %sh'],
        ['ایک دن پہلے', '1d میں'],
        ['%sدن پہلے', 'in %sd'],
        ['ایک ہفتہ پہلے', 'in 1w'],
        ['%s ہفتہ پہلے', 'in %sw'],
        [' ایک مہینہ پہلے', 'in 1mo'],
        ['پہلے %s مہینے', 'in %smo'],
        [' ایک سال پہلے', 'in 1yr'],
        ['%sسال پہلے', 'in %syr'],
    ][index] as [string, string];
}