import { NavLink } from "react-router-dom";
import { NewsItem } from "types/news";
import NoImageAvailable from 'assets/images/default_images/no-img.gif';
import MediaDetail from "../MediaDetail";

interface Props {
    newsItem: NewsItem
}

const SubHeadingCard = ({ newsItem }: Props) => {
    const { pk_id, image_link, heading, pub_date } = newsItem;
    return (
        <div className="media-list">
            <li key={pk_id}>
                <NavLink to={`/news/${pk_id}`}>
                    <div className="media-thumb">
                        <img className="b-lazy" src={image_link || NoImageAvailable} alt="" />
                    </div>
                    <MediaDetail heading={heading} dateTime={pub_date} />
                </NavLink>
            </li>
        </div>
    );
}

export default SubHeadingCard;