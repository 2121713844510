import abdul_qadir from 'assets/images/anchor_images/abdul_qadir.jpg';
import ali_moeen from 'assets/images/anchor_images/ali_moeen.jpg';
import asif_ali_durrani from 'assets/images/anchor_images/asif_ali_durrani.jpg';
import ata_qasmi from 'assets/images/anchor_images/ata_qasmi.jpg';
import ayaz_mir from 'assets/images/anchor_images/ayaz_mir.jpg';
import babar_awan from 'assets/images/anchor_images/babar_awan.jpg';
import baber_sattar from 'assets/images/anchor_images/baber_sattar.jpg';
import hamid_mir from 'assets/images/anchor_images/hamid_mir.jpg';
import haroon_rasheed from 'assets/images/anchor_images/haroon_rasheed.jpg';
import hassan_nisar from 'assets/images/anchor_images/hassan_nisar.jpg';
import imtiyaz_alam from 'assets/images/anchor_images/imtiyaz_alam.jpg';
import irshad_bhatti from 'assets/images/anchor_images/irshad_bhatti.jpg';
import javed_chaudhary from 'assets/images/anchor_images/javed_chaudhary.jpg';
import kamran_shahid from 'assets/images/anchor_images/kamran_shahid.jpg';
import mufti_munib_ur_rehman from 'assets/images/anchor_images/mufti_munib_ur_rehman.jpg';
import mujib_rehman from 'assets/images/anchor_images/mujib_rehman.jpg';
import nazeer_ahmed from 'assets/images/anchor_images/nazeer_ahmed.jpg';
import oriya_maqbool from 'assets/images/anchor_images/oriya_maqbool.jpg';
import rauf_klasra from 'assets/images/anchor_images/rauf_klasra.jpg';
import saleem_safi from 'assets/images/anchor_images/saleem_safi.jpg';
import sohail_waraich from 'assets/images/anchor_images/sohail_waraich.jpg';
import zafar_iqbal from 'assets/images/anchor_images/zafar_iqbal.jpg';

export const COLUMNISTS: any = {
    abdul_qadir,
    ali_moeen,
    asif_ali_durrani,
    ata_qasmi,
    ayaz_mir,
    babar_awan,
    baber_sattar,
    hamid_mir,
    haroon_rasheed,
    hassan_nisar,
    imtiyaz_alam,
    irshad_bhatti,
    javed_chaudhary,
    kamran_shahid,
    mufti_munib_ur_rehman,
    mujib_rehman,
    nazeer_ahmed,
    oriya_maqbool,
    rauf_klasra,
    saleem_safi,
    sohail_waraich,
    zafar_iqbal
}