interface Props{
    onChangePage: (page: number) => void;
    noOfPages: number;
    page: number
}

const Pagination = ({onChangePage, noOfPages, page}: Props) => {

    const onClickPage = (page: number) => {
        console.log('page', page);
        onChangePage(page);
    }

    if(noOfPages <= 1){
        return null
    }

    const pages = Array.from(Array(noOfPages).keys())

    return (
        <div className="main-pagination">
            {pages.map(el => {
                return <button className={page === el ? 'current' : ''} onClick={() => onClickPage(el)}>{el+1}</button>
            })}
            <button onClick={() => onClickPage(noOfPages-1)}>آخری صفحہ</button>
            <button onClick={() => onClickPage(0)}>پہلا صفحہ</button>
        </div>
    )
};

export default Pagination;
