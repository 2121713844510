import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "app/hook";
import { getLatestArticlesAction, selectLatestArticles } from "app/reducers/news";
import { tns } from "tiny-slider/src/tiny-slider";
import SectionHeader from "Components/Common/SectionHeader";
import MediaDetail from "Components/Common/MediaDetail";
import NoImageAvailable from 'assets/images/default_images/no-img.gif';
import { COLUMNISTS } from "utils/columnistsImages";
import { NavLink } from "react-router-dom";

const LatestArticles = () => {

    const [slider, setSlider] = useState<any>(null!)
    const dispatch = useAppDispatch();

    const latestArticles = useAppSelector(selectLatestArticles);
    const { rows = [] } = latestArticles || {};

    useEffect(() => {
        dispatch(getLatestArticlesAction(8, 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (rows && rows.length) {
            const tinySlider = tns({
                container: ".base2",
                items: 4,
                slideBy: 4,
                autoplay: false,
                nav: false,
                loop: true,
                axis: "horizontal",
                controls: false
            });

            setSlider(tinySlider);
        }
    }, [rows])

    const onGoTo = (dir: string) => {
        slider.goTo(dir);
    }

    return (
        <div className="articles-news">
            <SectionHeader sectionsName="تازہ ترین آرٹیکلز‎‎" onGoTo={onGoTo} />
            <div className="slider-container">
                <div id="base_wrapper">
                    <div className="base2" id="base2">
                        {rows.map((article, index) => {
                            const { heading, image_link, pub_date, pk_id } = article;
                            return (
                                <div key={pk_id} style={{ position: 'relative' }} className="item">
                                    <div className={`img img-${index}`}>
                                        <NavLink to={`articles/${pk_id}`}>
                                            <img
                                                className="b-lazy b-loaded"
                                                src={COLUMNISTS[image_link] || NoImageAvailable}
                                                alt=""
                                            />
                                            <MediaDetail
                                                heading={heading}
                                                dateTime={pub_date}
                                                timeStyle="media-time yellow-color"
                                            />
                                        </NavLink>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LatestArticles;
