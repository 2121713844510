import { Fragment, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hook";
import { getFeaturedNewsAction, selectFeaturedNews } from "app/reducers/news";
import Loader from 'Components/Common/Loader';
import { NewsItem } from "types/news";
import MainHeadingCard from '../Common/NewsCards/MainHeadingCard';
import SubHeadingCard from "Components/Common/NewsCards/SubHeadingCard";
import SectionHeader from "Components/Common/SectionHeader";

const Headlines = () => {

    const dispatch = useAppDispatch();
    const { NationalNews } = useAppSelector(selectFeaturedNews);

    const { data: Headlines, isLoading } = NationalNews || {};

    useEffect(() => {
        dispatch(getFeaturedNewsAction('National', 10, 0));
    }, [dispatch]);

    if (isLoading) {
        return (
            <Loader />
        )
    }

    return (
        <Fragment>
            <SectionHeader sectionsName="ہیڈلائین" />
            <div className="row">
                {
                    Headlines?.map((headline: NewsItem, index: number) =>
                        <div key={index} className="col-sm-6 photoes-media">
                            <ul className="mx-1">
                                {index < 2 ? <MainHeadingCard newsItem={headline} /> : <SubHeadingCard newsItem={headline} />}
                            </ul>
                        </div>)
                }
            </div>
        </Fragment>
    );
}

export default Headlines;