import { useAppDispatch, useAppSelector } from "app/hook";
import { getAllNewsAction, selectBusinessNews } from "app/reducers/news";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import NoImageAvailable from 'assets/images/default_images/no-img.gif';
import Loader from "Components/Common/Loader";
import SectionHeader from "Components/Common/SectionHeader";


const BusinessNews = () => {

    const dispatch = useAppDispatch();

    const newsData = useAppSelector(selectBusinessNews);
    const { data = [], isLoading } = newsData || {};

    useEffect(() => {
        dispatch(getAllNewsAction('business', 6, 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <div className="suggested-news">
            <SectionHeader sectionsName="کاروباری خبریں‎‎" />
            {isLoading ? (
                <Loader />
            ) : (
                <div className="row">

                    {data.map((news, index) => {
                        const { image_link, heading, pk_id } = news;
                        const img = image_link || NoImageAvailable;
                        return (
                            <div key={index} className="col-sm-6 col-md-4 media-box">
                                <NavLink to={`/news/${pk_id}`}>
                                    <div className="media-content">
                                        <img
                                            className="b-lazy b-loaded"
                                            src={img}
                                            alt=""
                                        />
                                    </div>
                                    <div className="media-des">
                                        <p>{heading}</p>
                                    </div>
                                </NavLink>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default BusinessNews;