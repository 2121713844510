
const FamousTags = () => {
  return (
    <div className="famous-tags">
        <div className="s-heading"><p>مقبول ٹیگ‎‎‎</p></div>
        <div className="tags-list">
            <p>
                
            </p>
        </div>
    </div>
  )
};

export default FamousTags;
