import Container from "./Container";

interface Props{
    children: React.ReactNode
}

const CategoryLayout = ({children}:Props) => {
  return (
    <Container>
        <div className="reversort">
            {children}
        </div>
    </Container>
  )
};

export default CategoryLayout;
