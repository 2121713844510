import TimeAgo from 'timeago-react';
import RedClock from 'assets/images/icons/clock-red.webp';
import YellowClock from 'assets/images/icons/clock-yellow.webp'

interface Props {
    heading: string,
    dateTime?: string,
    isYellow?: boolean,
    headlineStyle?: string
    timeStyle?: string
}

const MediaDetail = ({ heading, dateTime, headlineStyle = '', timeStyle = '' }: Props) => {

    const formattedDateTime = dateTime?.slice(0, 19).replace('T', ' ') || "";

    let headingStyles = '';
    let timeStyles = '';

    headingStyles = headlineStyle ? headlineStyle : "media-desc";
    timeStyles = !!timeStyle ? timeStyle : "media-time"

    return (
        <div className="media-detail">
            <p className={headingStyles}>{heading}</p>
            {
                !!dateTime
                    ? <p className={timeStyles}>
                        <img alt="" className="clock-icon" src={!!timeStyle ? YellowClock : RedClock} />
                        <span className="timeago" title={formattedDateTime}>
                            <TimeAgo
                                datetime={formattedDateTime}
                                locale='vi'
                            />
                        </span>
                    </p>
                    : null
            }
        </div>
    );
}

export default MediaDetail;