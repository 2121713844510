import { useAppDispatch, useAppSelector } from 'app/hook';
import { getFeaturedNewsAction, selectFeaturedNews } from 'app/reducers/news';
import { useEffect } from 'react';
import Loader from 'Components/Common/Loader';
import SubHeadingCard from 'Components/Common/NewsCards/SubHeadingCard';
import MainHeadingCard from 'Components/Common/NewsCards/MainHeadingCard';

const SliderNews = () => {


    const dispatch = useAppDispatch();

    const { InternationalNews } = useAppSelector(selectFeaturedNews);
    const { isLoading, data } = InternationalNews || {};
    const [mainNews, ...rest] = data || []

    useEffect(() => {
        dispatch(getFeaturedNewsAction('International', 5, 0));
    }, [dispatch]);

    if (isLoading) {
        return (
            <Loader />
        )
    }

    return (
        <>
            <div className="row" id="top-to-bottom">
                <div className="col-sm-6 photoes-media headericons">
                    {rest?.map((news: any, index: number) => <ul key={index} className='mx-1'><SubHeadingCard key={news.pk_id} newsItem={news} /></ul>)}
                </div>

                <div className="col-sm-6 photoes-media headermain mainHeadingHeight">
                    {mainNews ? <MainHeadingCard newsItem={mainNews} /> : null}
                </div>

            </div>
        </>
    );
}

export default SliderNews;