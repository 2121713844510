import { useAppDispatch, useAppSelector } from "app/hook";
import { getProminentColumnsAction, selectProminentColumns } from "app/reducers/news";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { tns } from "tiny-slider/src/tiny-slider";
import SectionHeader from "Components/Common/SectionHeader";
import NoImageAvailable from 'assets/images/default_images/no-img.gif';
import { COLUMNISTS } from '../../utils/columnistsImages';

const Columns = () => {

    const dispatch = useAppDispatch();
    const [slider, setSlider] = useState<any>(null!)
    const prominentColumns = useAppSelector(selectProminentColumns)

    useEffect(() => {
        dispatch(getProminentColumnsAction({ limit: 5, offset: 0 }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (prominentColumns && prominentColumns.length) {
            const tinySlider = tns({
                container: ".base5",
                items: 1,
                slideBy: 1,
                autoplay: false,
                nav: false,
                loop: true,
                axis: "horizontal",
                controls: false
            });

            setSlider(tinySlider);
        }
    }, [prominentColumns])

    const onGoTo = (dir: string) => {
        slider.goTo(dir);
    }

    return (
        <div className="prominent-news">
            <SectionHeader
                onGoTo={onGoTo}
                sectionsName="نمایاں کالمز"
            />
            <div className="slider-container">
                <div id="base_wrapper">
                    <div className="base5" id="base5">
                        {prominentColumns.map(columns => {
                            const { heading, image_link, pk_id } = columns || {};

                            return (
                                <div key={pk_id} style={{ position: "relative" }} className="item">
                                    <div className='img img-$k'>
                                        <NavLink to={`/articles/${pk_id}`}>
                                            <div className="news-img">
                                                <img
                                                    className="b-lazy b-loaded"
                                                    src={COLUMNISTS[image_link] || NoImageAvailable}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="news">
                                                <p className="news-text" style={{ height: '40px' }}>{heading}</p>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Columns;
