import { useAppDispatch, useAppSelector } from "app/hook";
import { getAllNewsAction, selectAllNews } from "app/reducers/category";
import { getFamousNewsAction, getFeaturedNewsAction, selectFamousNews, selectProminentNews } from "app/reducers/news";
import CategoryLayout from "Components/Common/Layout/Category";

import NewsContainer from "Components/Common/Layout/NewsContainer";
import Sidebar from "Components/Common/Layout/Sidebar";
import ImportantNews from "Components/News/ImportantNews";
import ProminentNews from "Components/News/ProminentNews";
import NewsBox from "Components/NewsBox";
import Pagination from "Components/Pagination";
import FamousTags from "Components/Sidebar/FamousTags";
import { NEWS_PER_PAGE } from "Constants";
import { useEffect, useState } from "react";

const Crime = () => {

    const[page, setPage] = useState(0);

    const dispatch = useAppDispatch();

    const prominentNews = useAppSelector(selectProminentNews)
    const allLatestNews = useAppSelector(selectAllNews);
    const famousNews = useAppSelector(selectFamousNews);
    const {rows = [], count = 0} = allLatestNews || {};

    useEffect(() => {
        dispatch(getAllNewsAction('crime'));
        dispatch(getFeaturedNewsAction('Crime'));
        dispatch(getFamousNewsAction(6));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onChangePage = (page: number) => {
        dispatch(getAllNewsAction('crime', 20, page));
        setPage(page);
    }

    const noOfPages = Math.ceil(count/NEWS_PER_PAGE);

    return (
        <CategoryLayout>
            <Sidebar>
                
                <ProminentNews prominentNews={prominentNews}/>
                <ImportantNews famousNews={famousNews}/>
                <FamousTags />
                
            </Sidebar>

            <NewsContainer>
                
                <NewsBox heading='جرائم' newsItems={rows} />

                <Pagination onChangePage={onChangePage} noOfPages={noOfPages} page={page}/>

            </NewsContainer>
        </CategoryLayout>
    );
}

export default Crime;