import SectionHeader from "Components/Common/SectionHeader";
import { NavLink } from "react-router-dom";

const NewsIndex = () => {
  return (
    <div className="glossary">
      <SectionHeader sectionsName="خبر کی فہرست" />
      <div className="gitems-list">
        <ul>
          <li><NavLink to="#"><img alt="" className="left-angle" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAACi0lEQVR4nO3cP4oUQRSA8W9FRDzAHkE8ggjCiGCygmcQAwPxCtV0gYkXEAxcDDdzBRMjFzyBGHkEEUMxWwO3Ycdxl+ke6tX794VDDzx+VBfTNdB7p5ySte9K7wGilNBCJbRQCS1UQguV0EIltFAJLVRCC5XQQl3tPUDr6lj3gbfAHeA98LQM5Zf0HHuezzrOkE+AW+c+/gQcSGO73TouQAZYAR/qWG9IzuMS+hLkqRXC2O6gt0CeWgGvmg90livoGchTDxuOs5Yb6AXIAJ8bjbORC+iFyF+Ax20m2sw89A7I98tQfraZajPT0Dsi/2gz1f8zC20JGYxCW0MGg9AWkcHYWcdC5K/AvZ7IYGhFW0YGI9DWkcEAtAdkUA7tBRkUQ3tCBqXQ3pBBIbRHZFAG7RUZFEF7RgYl0N6RQQF0BGToDB0FGTpCR0KGTtDRkKEDdERkEIaOigyC0JGRQQg6OjLIrehDAiODHPTtmdc/94QMctDvZl5/VMc65w5QnxT0M+DjjOv3gRNP2CLQZSi/gUcExhb7eRcdW/SBJTK2+CN4VOwuh0oRsbsdk0bD7nrwHwm7+19ZUbC7Q0MMbBXQ4B9bDTT4xlYFDX6x1UGDT2yV0OAPWy00+MJWDQ1+sNVDgw9sE9BgH9sMNNjGNgUNdrHNQYNNbJPQYA/bLDTYwjb1doOLqmO9DhwDD2Z87TtwtwzlW5up1jO9oqd2WNmHbSbazAU0LMa+2WicjdxAwyLs1w3HWcvFHv1vW+7Zb8pQngiN5GtFT22xskWRwSk0XIotjgxOt47z1bFeA17w943ox2UoL3vM4R5aS263Dm0ltFAJLVRCC5XQQiW0UAktVEILldBCJbRQCS3UH854gcRBI/hHAAAAAElFTkSuQmCC" />ماحولیات‎</NavLink></li>
          <li><NavLink to="<?= base_url() ?>science"><img alt="" className="left-angle" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAACi0lEQVR4nO3cP4oUQRSA8W9FRDzAHkE8ggjCiGCygmcQAwPxCtV0gYkXEAxcDDdzBRMjFzyBGHkEEUMxWwO3Ycdxl+ke6tX794VDDzx+VBfTNdB7p5ySte9K7wGilNBCJbRQCS1UQguV0EIltFAJLVRCC5XQQl3tPUDr6lj3gbfAHeA98LQM5Zf0HHuezzrOkE+AW+c+/gQcSGO73TouQAZYAR/qWG9IzuMS+hLkqRXC2O6gt0CeWgGvmg90livoGchTDxuOs5Yb6AXIAJ8bjbORC+iFyF+Ax20m2sw89A7I98tQfraZajPT0Dsi/2gz1f8zC20JGYxCW0MGg9AWkcHYWcdC5K/AvZ7IYGhFW0YGI9DWkcEAtAdkUA7tBRkUQ3tCBqXQ3pBBIbRHZFAG7RUZFEF7RgYl0N6RQQF0BGToDB0FGTpCR0KGTtDRkKEDdERkEIaOigyC0JGRQQg6OjLIrehDAiODHPTtmdc/94QMctDvZl5/VMc65w5QnxT0M+DjjOv3gRNP2CLQZSi/gUcExhb7eRcdW/SBJTK2+CN4VOwuh0oRsbsdk0bD7nrwHwm7+19ZUbC7Q0MMbBXQ4B9bDTT4xlYFDX6x1UGDT2yV0OAPWy00+MJWDQ1+sNVDgw9sE9BgH9sMNNjGNgUNdrHNQYNNbJPQYA/bLDTYwjb1doOLqmO9DhwDD2Z87TtwtwzlW5up1jO9oqd2WNmHbSbazAU0LMa+2WicjdxAwyLs1w3HWcvFHv1vW+7Zb8pQngiN5GtFT22xskWRwSk0XIotjgxOt47z1bFeA17w943ox2UoL3vM4R5aS263Dm0ltFAJLVRCC5XQQiW0UAktVEILldBCJbRQCS3UH854gcRBI/hHAAAAAElFTkSuQmCC" />سائنس اور ٹیکنالوجی‎</NavLink>
          </li>
          <li><NavLink to="#"><img alt="" className="left-angle" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAACi0lEQVR4nO3cP4oUQRSA8W9FRDzAHkE8ggjCiGCygmcQAwPxCtV0gYkXEAxcDDdzBRMjFzyBGHkEEUMxWwO3Ycdxl+ke6tX794VDDzx+VBfTNdB7p5ySte9K7wGilNBCJbRQCS1UQguV0EIltFAJLVRCC5XQQl3tPUDr6lj3gbfAHeA98LQM5Zf0HHuezzrOkE+AW+c+/gQcSGO73TouQAZYAR/qWG9IzuMS+hLkqRXC2O6gt0CeWgGvmg90livoGchTDxuOs5Yb6AXIAJ8bjbORC+iFyF+Ax20m2sw89A7I98tQfraZajPT0Dsi/2gz1f8zC20JGYxCW0MGg9AWkcHYWcdC5K/AvZ7IYGhFW0YGI9DWkcEAtAdkUA7tBRkUQ3tCBqXQ3pBBIbRHZFAG7RUZFEF7RgYl0N6RQQF0BGToDB0FGTpCR0KGTtDRkKEDdERkEIaOigyC0JGRQQg6OjLIrehDAiODHPTtmdc/94QMctDvZl5/VMc65w5QnxT0M+DjjOv3gRNP2CLQZSi/gUcExhb7eRcdW/SBJTK2+CN4VOwuh0oRsbsdk0bD7nrwHwm7+19ZUbC7Q0MMbBXQ4B9bDTT4xlYFDX6x1UGDT2yV0OAPWy00+MJWDQ1+sNVDgw9sE9BgH9sMNNjGNgUNdrHNQYNNbJPQYA/bLDTYwjb1doOLqmO9DhwDD2Z87TtwtwzlW5up1jO9oqd2WNmHbSbazAU0LMa+2WicjdxAwyLs1w3HWcvFHv1vW+7Zb8pQngiN5GtFT22xskWRwSk0XIotjgxOt47z1bFeA17w943ox2UoL3vM4R5aS263Dm0ltFAJLVRCC5XQQiW0UAktVEILldBCJbRQCS3UH854gcRBI/hHAAAAAElFTkSuQmCC" />دلچسپ و عجیب‎</NavLink></li>
          <li><NavLink to="#"><img alt="" className="left-angle" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAACi0lEQVR4nO3cP4oUQRSA8W9FRDzAHkE8ggjCiGCygmcQAwPxCtV0gYkXEAxcDDdzBRMjFzyBGHkEEUMxWwO3Ycdxl+ke6tX794VDDzx+VBfTNdB7p5ySte9K7wGilNBCJbRQCS1UQguV0EIltFAJLVRCC5XQQl3tPUDr6lj3gbfAHeA98LQM5Zf0HHuezzrOkE+AW+c+/gQcSGO73TouQAZYAR/qWG9IzuMS+hLkqRXC2O6gt0CeWgGvmg90livoGchTDxuOs5Yb6AXIAJ8bjbORC+iFyF+Ax20m2sw89A7I98tQfraZajPT0Dsi/2gz1f8zC20JGYxCW0MGg9AWkcHYWcdC5K/AvZ7IYGhFW0YGI9DWkcEAtAdkUA7tBRkUQ3tCBqXQ3pBBIbRHZFAG7RUZFEF7RgYl0N6RQQF0BGToDB0FGTpCR0KGTtDRkKEDdERkEIaOigyC0JGRQQg6OjLIrehDAiODHPTtmdc/94QMctDvZl5/VMc65w5QnxT0M+DjjOv3gRNP2CLQZSi/gUcExhb7eRcdW/SBJTK2+CN4VOwuh0oRsbsdk0bD7nrwHwm7+19ZUbC7Q0MMbBXQ4B9bDTT4xlYFDX6x1UGDT2yV0OAPWy00+MJWDQ1+sNVDgw9sE9BgH9sMNNjGNgUNdrHNQYNNbJPQYA/bLDTYwjb1doOLqmO9DhwDD2Z87TtwtwzlW5up1jO9oqd2WNmHbSbazAU0LMa+2WicjdxAwyLs1w3HWcvFHv1vW+7Zb8pQngiN5GtFT22xskWRwSk0XIotjgxOt47z1bFeA17w943ox2UoL3vM4R5aS263Dm0ltFAJLVRCC5XQQiW0UAktVEILldBCJbRQCS3UH854gcRBI/hHAAAAAElFTkSuQmCC" />زیادہ پڑھی جانے والی خبریں‎</NavLink></li>
          <li><NavLink to="#"><img alt="" className="left-angle" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAACi0lEQVR4nO3cP4oUQRSA8W9FRDzAHkE8ggjCiGCygmcQAwPxCtV0gYkXEAxcDDdzBRMjFzyBGHkEEUMxWwO3Ycdxl+ke6tX794VDDzx+VBfTNdB7p5ySte9K7wGilNBCJbRQCS1UQguV0EIltFAJLVRCC5XQQl3tPUDr6lj3gbfAHeA98LQM5Zf0HHuezzrOkE+AW+c+/gQcSGO73TouQAZYAR/qWG9IzuMS+hLkqRXC2O6gt0CeWgGvmg90livoGchTDxuOs5Yb6AXIAJ8bjbORC+iFyF+Ax20m2sw89A7I98tQfraZajPT0Dsi/2gz1f8zC20JGYxCW0MGg9AWkcHYWcdC5K/AvZ7IYGhFW0YGI9DWkcEAtAdkUA7tBRkUQ3tCBqXQ3pBBIbRHZFAG7RUZFEF7RgYl0N6RQQF0BGToDB0FGTpCR0KGTtDRkKEDdERkEIaOigyC0JGRQQg6OjLIrehDAiODHPTtmdc/94QMctDvZl5/VMc65w5QnxT0M+DjjOv3gRNP2CLQZSi/gUcExhb7eRcdW/SBJTK2+CN4VOwuh0oRsbsdk0bD7nrwHwm7+19ZUbC7Q0MMbBXQ4B9bDTT4xlYFDX6x1UGDT2yV0OAPWy00+MJWDQ1+sNVDgw9sE9BgH9sMNNjGNgUNdrHNQYNNbJPQYA/bLDTYwjb1doOLqmO9DhwDD2Z87TtwtwzlW5up1jO9oqd2WNmHbSbazAU0LMa+2WicjdxAwyLs1w3HWcvFHv1vW+7Zb8pQngiN5GtFT22xskWRwSk0XIotjgxOt47z1bFeA17w943ox2UoL3vM4R5aS263Dm0ltFAJLVRCC5XQQiW0UAktVEILldBCJbRQCS3UH854gcRBI/hHAAAAAElFTkSuQmCC" />لائف اسٹائل‎</NavLink></li>
          <li><NavLink to="#"><img alt="" className="left-angle" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAACi0lEQVR4nO3cP4oUQRSA8W9FRDzAHkE8ggjCiGCygmcQAwPxCtV0gYkXEAxcDDdzBRMjFzyBGHkEEUMxWwO3Ycdxl+ke6tX794VDDzx+VBfTNdB7p5ySte9K7wGilNBCJbRQCS1UQguV0EIltFAJLVRCC5XQQl3tPUDr6lj3gbfAHeA98LQM5Zf0HHuezzrOkE+AW+c+/gQcSGO73TouQAZYAR/qWG9IzuMS+hLkqRXC2O6gt0CeWgGvmg90livoGchTDxuOs5Yb6AXIAJ8bjbORC+iFyF+Ax20m2sw89A7I98tQfraZajPT0Dsi/2gz1f8zC20JGYxCW0MGg9AWkcHYWcdC5K/AvZ7IYGhFW0YGI9DWkcEAtAdkUA7tBRkUQ3tCBqXQ3pBBIbRHZFAG7RUZFEF7RgYl0N6RQQF0BGToDB0FGTpCR0KGTtDRkKEDdERkEIaOigyC0JGRQQg6OjLIrehDAiODHPTtmdc/94QMctDvZl5/VMc65w5QnxT0M+DjjOv3gRNP2CLQZSi/gUcExhb7eRcdW/SBJTK2+CN4VOwuh0oRsbsdk0bD7nrwHwm7+19ZUbC7Q0MMbBXQ4B9bDTT4xlYFDX6x1UGDT2yV0OAPWy00+MJWDQ1+sNVDgw9sE9BgH9sMNNjGNgUNdrHNQYNNbJPQYA/bLDTYwjb1doOLqmO9DhwDD2Z87TtwtwzlW5up1jO9oqd2WNmHbSbazAU0LMa+2WicjdxAwyLs1w3HWcvFHv1vW+7Zb8pQngiN5GtFT22xskWRwSk0XIotjgxOt47z1bFeA17w943ox2UoL3vM4R5aS263Dm0ltFAJLVRCC5XQQiW0UAktVEILldBCJbRQCS3UH854gcRBI/hHAAAAAElFTkSuQmCC" />انٹرٹینمنٹ‎</NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
};

export default NewsIndex;
