
interface Props {
    onClick?: (event: any) => void;
}

const RightArrowButton = ({ onClick }: Props) => {

    return (
        <span onClick={onClick} className="sliderButtons" style={{ cursor: 'pointer' }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ width: '48px', height: '40px', paddingLeft: '4px', }}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
        </span>
    );
}

export default RightArrowButton;