import { useAppDispatch } from "app/hook";
import { clearCategoryReducer } from "app/reducers/category";
import LatestNews from "Components/Home/LatestNews";
import { NavLink, useLocation } from "react-router-dom";

const Header = () => {

    const { pathname } = useLocation();
    const path = pathname.split("/").pop();

    const dispatch = useAppDispatch();

    const onClick = (category: string) => {
        if (category !== path) {
            dispatch(clearCategoryReducer());
        }
    }

    return (
        <div className="content-wrapper">
            <header>
                <nav className="container-fluid nav-container">
                    <div className="row">
                        <div className="col-md-3 p0 col-sm-12 col-xs-12 col-md-push-9 right-nav">
                            <div className="site-name pull-right">
                                <NavLink to="/">
                                    <img src="https://web.archive.org/web/20190311164642im_/https://newslink.pk/assets/images/logo/news_link_logo.webp" alt="Newslink Logo" />
                                </NavLink>
                            </div>
                            <div className="pull-right search-link">
                                <div className="search-overlay">
                                    <NavLink to="#" title="Search" className="search-icon">
                                        <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAACg0lEQVRIicWXzUuUURSHf2cYBgkXISERIhElLVqJgZgLqWhVFK1KIlrWPyDRwk3rVi2iZYtwF4W72iRUBBVkgxAIBZVRYV+UiZrnaTHv2J07887cUcTfZeB979cz5+OeuWMIbYUKW0KVVEyZhFM0sz5J/ZJ2SHJJnyQ9lfRBJm+bTLMGfcAY8J7GWgXKwAVgV9O9otYMegyYBpZyoKEWgClg//rBUADGgZ8JwFhzwJkUsNVlNTouaUJSZ9D7V9JLSfckvZNUkrRP0mlJe6PofZR0RKbX6TGGfmA2iuEMMAIU42/t7tuAc5mloaZaxTze6FoGq6oMDLd0HIxG8AXgfBLY3buAxWDxIjCQmiyZ5aHKQCEFfCpaOJkM/e/2mjC5e2/e/LByHYqSaSK1FkiSFeyPpDtBV8HMBvPmr4HNrDvo/y3pSzvgTLPR+86WYNWWT88+7Wo5dWIIng+eOyVtXwe4N3r/lgJ+FjyXJJ1oC4mKkk5Gvc+bzF87Dr3RGf4O9LRxnEaAlepid58DSi2PU7b4dnSkHgB7EqDDWYWrasXdx5utiTc4HFWgpQyebzkMZMVizVvuPgMcaAaOLwIPJV2VdF2VLC9JOiqpLHRX0qSkH1ludEs6m413hJuYmdQqORtY0AHcCuMVeeBrFv9G46HKwO40V9fCL1L/q5OnVXd/C/xqAB9KB1fgBSoxz7O+qkXgJjAEXGkAn25kef1FoHE8eoARMzuoymVvGZg3s8eSHsmy4lOp/aOSbqj2IvFK0iWZnuTHeKOt4qnL1F+dXgDbWrt6A83dC8Cou9fA3X1wU8EBfCyw/DPQVR1Pi/EGhDNgZgOS7sv0ptq/6eA8bdl/p3+ch3auT1ZfpQAAAABJRU5ErkJggg==" />
                                    </NavLink>
                                    <div className="search">
                                        <form role="search">
                                            <input
                                                name="search_keyword"
                                                type="text"
                                                className="query live-search-query"
                                                placeholder="ٹیگ تلاش کریں ---"
                                            />
                                            <button className="search-button" type="submit"><img alt="" src="base_url('assets/images/icons/search.ico') " /></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-9 p0 col-sm-12 col-xs-12 col-md-pull-3">
                            <ul className="mynavbar" id="mynavbar">
                                <li className={`${path === '' ? ' active ' : ''}`}>
                                    <NavLink to="/">صفحۂ اول</NavLink></li>

                                <li onClick={() => onClick('latest')} className={`${path === 'latest' ? ' active ' : ''}`}>
                                    <NavLink to="/latest">تازہ ترین خبریں</NavLink></li>

                                <li onClick={() => onClick('pakistan')} className={`${path === 'pakistan' ? ' active ' : ''}`}>
                                    <NavLink to="/pakistan">پاکستان</NavLink></li>

                                <li className={`${path === 'international' ? ' active ' : ''}`}>
                                    <NavLink to="/international">بین الاقوامی</NavLink></li>

                                <li className={`${path === 'sports' ? ' active ' : ''}`}>
                                    <NavLink to="/sports">کھیل</NavLink></li>

                                <li className={`${path === 'entertainment' ? ' active ' : ''}`}>
                                    <NavLink to="/entertainment">تفریح</NavLink>
                                </li>

                                <li className={`${path === 'business' ? ' active ' : ''}`}>
                                    <NavLink to="/business">کاروبار</NavLink>
                                </li>

                                <li className={`${path === 'science' ? ' active ' : ''}`}>
                                    <NavLink to="/science">سائنس/صحت</NavLink>
                                </li>

                                <li className={`${path === 'crime' ? ' active ' : ''}`}>
                                    <NavLink to="/crime">جرائم</NavLink>
                                </li>

                                <li className={`${path  === 'articles' ? ' active ' : ''}`}>
                                    <NavLink to="/articles"> آرٹیکلز / کالمز</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {!path ? <LatestNews /> : null}
            </header>
        </div>
    );
}

export default Header;