import { NavLink } from "react-router-dom";
import { NewsItem } from "types/news";
import MediaDetail from "../MediaDetail";

interface Props {
    newsItem: NewsItem
}

const MainHeadingCard = ({ newsItem }: Props) => {
    const { pk_id, image_link, heading, pub_date } = newsItem;
    return (
        <div className="heading-media mainHeadingImg">
            <NavLink to={`/news/${pk_id}`}>
                <img className="b-lazy mainHeadingImg" src={image_link} data-src="" alt="" />
                <div className="media-caption">
                    <MediaDetail
                        heading={heading}
                        headlineStyle="caption-text"
                        timeStyle="media-time yellow-color"
                        dateTime={pub_date}
                    />
                </div>
            </NavLink>
        </div>
    );
}

export default MainHeadingCard;