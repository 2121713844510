interface Props {
    heading: string;
    link: string;
}

const OtherNewsLink = ({ heading, link }: Props) =>

    <div className="news-like-this clearfix">
        <p className="head pull-right">اس جیسی خبر پڑھیں:</p>
        <p className="text pull-right">
            <a href={link}>{heading}</a>
        </p>
    </div>

export default OtherNewsLink;