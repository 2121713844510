import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { AppDispatch, AppState } from "app/store";
import { getAllNews, getProminentColumns} from 'services/newsService';
import { Pagination } from 'types/common';
import { NewsItemResponse, NewsItems } from 'types/news';

interface CategorySlice{
    isLoading: boolean;
    allNews: NewsItemResponse;
    prominentNews: NewsItems;
    importantNews: NewsItems;
    famousTags: any[];
    prominentColumns: any[];
    news: NewsItemResponse
}

const initialState:CategorySlice = {
    isLoading: false,
    importantNews: [],
    allNews: null!,
    prominentNews: [],
    famousTags: [],
    prominentColumns: [],
    news: null!
}

export const categorySlice = createSlice({
    name: 'categoryReducer',
    initialState,
    reducers: {
        setAllNews: (state, action:PayloadAction<NewsItemResponse>) => {
            state.allNews = action.payload;
        },
        // setInternationalNews: (state, action:PayloadAction<NewsItems>) => {
        //     state.internationalNews = action.payload;
        // },
        // setSportsNews: (state, action:PayloadAction<NewsItems>) => {
        //     state.sportsNews = action.payload;
        // },
        // setBusinessNews: (state, action:PayloadAction<NewsItems>) => {
        //     state.businessNews = action.payload;
        // },
        // setFamousNews: (state, action:PayloadAction<NewsItems>) => {
        //     state.famousNews = action.payload;
        // },
        // setLatestNews: (state, action:PayloadAction<NewsItems>) => {
        //     state.latestNews = action.payload;
        // },
        setProminentColumns: (state, action:PayloadAction<any[]>) => {
            state.prominentColumns = action.payload;
        },
        // setSuggestedNews: (state, action:PayloadAction<NewsItems>) => {
        //     state.suggestedNews = action.payload;
        // },
        // setLatestArticles: (state, action:PayloadAction<any[]>) => {
        //     state.latestArticles = action.payload;
        // },
        // setTickerNews: (state, action:PayloadAction<NewsItems>) => {
        //     state.tickerNews = action.payload;
        // },
        setLoading: (state) => {
            state.isLoading = true;
        },
        clearLoading: (state) => {
            state.isLoading = false;
        },
        setProminentNews: (state, action:PayloadAction<NewsItems>) => {
            state.prominentNews = action.payload;
        },
        clearCategoryReducer: (state) => {
            state.allNews = {rows: [], count: 0};
            state.prominentNews = [];
        },
    }
})

export default categorySlice.reducer

export const {
    // setFamousNews,
    // setLatestNews, 
    // setBusinessNews, 
    // setInternationalNews, 
    // setSportsNews, 
    setProminentColumns,
    // setSuggestedNews,
    // setLatestArticles,
    // setTickerNews,
    setAllNews,
    setLoading, 
    clearLoading,
    clearCategoryReducer
} = categorySlice.actions

// export const selectInternationalNews = (state:AppState): NewsItems => state.categoryReducer.internationalNews;

// export const selectSportsNews = (state:AppState): NewsItems => state.categoryReducer.sportsNews;

// export const selectBusinessNews = (state:AppState): NewsItems => state.categoryReducer.businessNews;

// export const selectLatestNews = (state:AppState): NewsItems => state.categoryReducer.latestNews;

// export const selectFamousNews = (state:AppState): NewsItems => state.categoryReducer.famousNews;

// export const selectSuggestedNews = (state:AppState): any[] => state.categoryReducer.suggestedNews;

// export const selectLatestArticles = (state:AppState): any[] => state.categoryReducer.latestArticles;

// export const selectTickerNews = (state:AppState): NewsItems => state.categoryReducer.tickerNews;

export const selectAllNews = (state:AppState): NewsItemResponse => state.categoryReducer.allNews;


export const getAllNewsAction = (category: string, limit=20, offset=0) => async (dispatch: AppDispatch) => {
        
    dispatch(setLoading());

    const allNews = await getAllNews(category, {limit, offset});

    dispatch(setAllNews(allNews));

    dispatch(clearLoading());   
}


export const getProminentColumnsAction = (pagination: Pagination) => async (dispatch: AppDispatch) => {

    const prominentColumns = await getProminentColumns(pagination);

    dispatch(setProminentColumns(prominentColumns));
}

