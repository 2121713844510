import { useAppDispatch, useAppSelector } from "app/hook";
import { getAllNewsAction, selectSportsNews } from "app/reducers/news";
import { useEffect, useState } from "react";
import 'tiny-slider/dist/tiny-slider.css';
import { NavLink } from "react-router-dom";
import NoImageAvailable from 'assets/images/default_images/no-img.gif';
import { tns } from "tiny-slider/src/tiny-slider";
import SectionHeader from "Components/Common/SectionHeader";
import MediaDetail from "Components/Common/MediaDetail";

const Sports = () => {

    const dispatch = useAppDispatch();

    const [slider, setSlider] = useState<any>(null!)

    const sportsNews = useAppSelector(selectSportsNews);
    const { data = [], isLoading } = sportsNews || {};

    useEffect(() => {
        dispatch(getAllNewsAction('sports', 8, 0))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (!isLoading && data && data?.length) {
            const tinySlider = tns({
                container: ".base3",
                items: 3,
                slideBy: 3,
                autoplay: false,
                nav: false,
                loop: true,
                axis: "horizontal",
                controls: false
            });

            setSlider(tinySlider);
        }
    }, [data, isLoading])

    const onGoTo = (dir: string) => {
        slider.goTo(dir);
    }

    return (
        <div className="sports-news">
            <SectionHeader sectionsName="کھیلوں کی خبریں‎‎" onGoTo={onGoTo} />
            <div className="slider-container">
                <div id="base_wrapper">

                    <div className="base3" id="base3">
                        {data?.map((news, index: number) => {
                            const { image_link, heading, pub_date, pk_id } = news;
                            const img = image_link || NoImageAvailable;

                            return (
                                <div key={pk_id} className="item">
                                    <div className={`img img-${index + 1}`}>
                                        <NavLink to={`/news/${pk_id}`}>
                                            <img
                                                className="b-lazy b-loaded"
                                                src={img}
                                                alt=""
                                            />
                                            <MediaDetail heading={heading} dateTime={pub_date} timeStyle="media-time yellow-color" />
                                        </NavLink>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Sports;