import { NewsItems } from 'types/news';
import NoImageAvailable from 'assets/images/default_images/no-img.gif';
import SectionHeader from 'Components/Common/SectionHeader';
import MediaDetail from 'Components/Common/MediaDetail';

interface Props {
    famousNews: NewsItems
}

const ImportantNews = ({ famousNews }: Props) => {

    return (
        <div className="other-similar-media-news">
            <SectionHeader sectionsName='اہم خبریں‎‎' />
            <ul>

                {famousNews.map(news => {
                    const { image_link, heading, pub_date = '', pk_id } = news || {};
                    const img = image_link || NoImageAvailable;
                    return (
                        <li>
                            <a href={`news/${pk_id}`}>
                                <div className="media-thumb">
                                    <img
                                        className="b-lazy b-loaded"
                                        src={img}
                                        height="86px"
                                        width="86px"
                                        alt=""
                                    />
                                </div>
                                <MediaDetail heading={heading} dateTime={pub_date} timeStyle='media-news-time'/>
                            </a>
                        </li>
                    )
                })}

            </ul>
        </div>
    )
};

export default ImportantNews;
